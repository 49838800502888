import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'

import { ResourceResultProps } from '../type'
import { formatDate } from '../utils'

const listPreview = Bem('ListPreview')

export const ListPreviewResource = (props: ResourceResultProps) => {
  const {
    articleUrl,
    assetType,
    thumbnail,
    categoryName,
    articleName,
    mobileHeadline,
    date,
    linkAttrs = {},
    articleSummary,
    sponsor,
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src, alt } = thumbnail
  const excludeTypeDate = ['Webinar', 'Live Event', 'Virtual Event']
  return (
    <div className={listPreview()} data-testid="list-preview">
      <Link
        to={articleUrl}
        trackGtmClick={trackGtmClick}
        {...linkAttrs}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
        }
      >
        <div className={listPreview('Container')}>
          <div className={listPreview('ImageWrapper')}>
            <div title={alt}>
              <Image
                className={listPreview('ResourceImage')}
                src={src}
                alt={alt}
                fallback="brand"
                width={300}
              />
            </div>
          </div>
          <div className={listPreview('ResourceContentWrapper')}>
            <div className={listPreview('KeywordWrapper')}>
              <Keyword
                variant="alternate"
                url={''}
                trackGtmClick={trackGtmClick}
                title={categoryName}
                className={listPreview('Keyword')}
              />
            </div>
            <div className={listPreview('TitleWrapper')}>
              <p
                className={listPreview('Title')}
                data-testid="preview-default-title"
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(articleName),
                }}
              />
              <div
                className={listPreview('ResourceSummary')}
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(articleSummary || ''),
                }}
              />
            </div>
            <div className={listPreview('MobileTitleWrapper')}>
              <p
                className={listPreview('Title')}
                data-testid="preview-mobile-title"
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(mobileHeadline),
                }}
              />
              <div
                className={listPreview('ResourceSummary')}
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(articleSummary || ''),
                }}
              />
            </div>
            <div className={listPreview('Sponsor')}>
              <span>{sponsor}</span>
            </div>
            {!excludeTypeDate.includes(assetType) && (
              <div className={listPreview('Footer')}>
                <span className={listPreview('Date')}>{formatDate(date)}</span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
