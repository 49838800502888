import { ContentType } from '@scm/ui-component-article-read-time'
import { IconType } from '@scm/ui-component-icon'
import {
  ContentHardBreak,
  ContentItem,
  ContentJSON,
  ContentParagraph,
  ContentText,
  IContentImage,
} from '@scm/ui-module-content/src/type'

export const getContentIconUrl = (
  contentType: ContentType,
): IconType | null => {
  switch (contentType) {
    case 'Article':
      return null
    case 'Document':
      return 'Document'
    case 'Magazine':
      return null
    case 'Video':
      return 'Play'
    case 'Webinar':
      return 'ChalkBoard'
    case 'Podcast':
      return 'Microphone'
    case 'Slideshow':
      return 'Images'
    case 'ExternalLink':
      return 'ExternalLink'
    case 'PublicationIssue':
    case 'Recipe':
      return null
    default:
      return null
  }
}

type ParagraphContentItem =
  | ContentParagraph
  | ContentText
  | ContentHardBreak
  | IContentImage

export const formatDate = (date: string) => {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

const SUMMARY_LENGTH = 1020
const ADDITIONAL_SPACE_CHAR = 1

export const getSummaryString = (
  numberOfChars: number,
  summary: string,
  contentItem: ContentText,
) => {
  const remainingChars = SUMMARY_LENGTH - numberOfChars

  if (contentItem.text.length > remainingChars) {
    return {
      calculatedSummary:
        summary + contentItem.text.substring(0, remainingChars) + '...',
      newNumberOfChars: SUMMARY_LENGTH,
    }
  }

  return {
    calculatedSummary: summary + contentItem.text + '\n',
    newNumberOfChars:
      numberOfChars + contentItem.text.length + ADDITIONAL_SPACE_CHAR,
  }
}

type AccumulatorProps = {
  summary: string
  numberOfChars: number
}

const isContentToCount = (
  contentItem: ParagraphContentItem,
  numberOfChars: number,
) =>
  'text' in contentItem &&
  contentItem.type === 'text' &&
  numberOfChars < SUMMARY_LENGTH

export const getSummaryFromParagraph = (
  acc: AccumulatorProps,
  bodyItem: ContentParagraph,
) => {
  const { summary, numberOfChars: charsCount } = acc
  let numberOfChars = charsCount

  const paragraphContent = bodyItem.content as ParagraphContentItem[]
  const paragraphSummary = paragraphContent.reduce(
    (accSummary: string, contentItem: ParagraphContentItem) => {
      if (isContentToCount(contentItem, numberOfChars)) {
        const { calculatedSummary, newNumberOfChars } = getSummaryString(
          numberOfChars,
          accSummary,
          contentItem as ContentText,
        )

        numberOfChars = newNumberOfChars
        return calculatedSummary
      }
      return accSummary
    },
    summary,
  )

  return { summary: paragraphSummary, numberOfChars }
}

const isParagraphToCount = (bodyItem: ContentItem, numberOfChars: number) =>
  'content' in bodyItem &&
  bodyItem.type === 'paragraph' &&
  numberOfChars < SUMMARY_LENGTH

export const getSummaryFromBody = (body: ContentJSON | undefined) => {
  if (!body || body.length === 0) return ''

  const summaryData = body.reduce(
    (acc, bodyItem) => {
      if (isParagraphToCount(bodyItem, acc.numberOfChars)) {
        return getSummaryFromParagraph(acc, bodyItem as ContentParagraph)
      }
      return acc
    },
    { summary: '', numberOfChars: 0 },
  )

  return summaryData.summary
}

export const getSummary = ({
  articleSummary,
  articleBodyJsonSummary,
  articleBody,
}: {
  articleSummary?: string
  articleBodyJsonSummary?: string
  articleBody?: ContentJSON
}) => {
  if (articleSummary) return articleSummary
  if (articleBodyJsonSummary) return articleBodyJsonSummary
  return getSummaryFromBody(articleBody)
}
