import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'

import { HorizontalPreviewContentCardProps } from '../type'
import { formatDate, getContentIconUrl } from '../utils'

const horizontalCard = Bem('HorizontalCard')

export const HorizontalCard = (props: HorizontalPreviewContentCardProps) => {
  const {
    articleUrl,
    listPageUrl,
    thumbnail,
    categoryName,
    categoryUrl,
    articleName,
    mobileHeadline,
    date,
    contentType,
    linkAttrs = {},
    isShowDate = true,
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src, alt } = thumbnail
  const icon = getContentIconUrl(contentType)

  return (
    <div className={horizontalCard()}>
      <div className={horizontalCard('ImageWrapper')}>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          {...linkAttrs}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        >
          <span className={horizontalCard('ImageScreenReaderText')}>{alt}</span>
          <Image
            className={horizontalCard('Image')}
            src={src}
            alt=""
            fallback="brand"
            width={300}
          />
        </Link>
        {icon && (
          <Link
            className={horizontalCard('IconWrapper')}
            title={`Link to all ${contentType.toLowerCase()}`}
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            {...linkAttrs}
            data-testid="content-preview-icon-link"
          >
            <Icon icon={icon} className={horizontalCard('Icon')} />
          </Link>
        )}
      </div>
      <div className={horizontalCard('ContentWrapper')}>
        <div className={horizontalCard('KeywordWrapper')}>
          <Keyword
            variant="alternate"
            url={categoryUrl}
            title={categoryName}
            trackGtmClick={trackGtmClick}
            className={horizontalCard('Keyword')}
          />
        </div>
        <div className={horizontalCard('TitleWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={horizontalCard('Title')}
            data-testid="preview-default-title"
            {...linkAttrs}
            dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          />
        </div>
        <div className={horizontalCard('MobileTitleWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={horizontalCard('Title')}
            data-testid="preview-mobile-title"
            {...linkAttrs}
            dangerouslySetInnerHTML={{
              __html:
                renderMarkdown(mobileHeadline) || renderMarkdown(articleName),
            }}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          />
        </div>
        {isShowDate && (
          <p
            className={horizontalCard('Date')}
            data-testid="horizontalCardDate"
          >
            {formatDate(date)}
          </p>
        )}
      </div>
    </div>
  )
}
