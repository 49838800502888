import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Contributors } from '@scm/ui-component-contributors'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, SPONSORED_CONTENT, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { ListPreviewProps } from '../type'
import { formatDate, getContentIconUrl } from '../utils'

const listPreview = Bem('ListPreview')

// eslint-disable-next-line
export const ListPreview = (props: ListPreviewProps) => {
  const {
    articleUrl,
    listPageUrl,
    thumbnail,
    categoryName,
    categoryUrl,
    articleName,
    mobileHeadline,
    date,
    webinarStartTime,
    timeRead,
    contributors = [],
    contentType,
    variant,
    showContributorTitle,
    linkAttrs = {},
    articleSummary,
    publicationType,
    isFirstWebinarInContent,
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src, alt } = thumbnail
  const icon = getContentIconUrl(contentType)
  const isPublicationType = contentType
    ? contentType === 'PublicationIssue'
    : false

  return (
    <div className={listPreview('', { first: isFirstWebinarInContent })}>
      <div className={listPreview('Container')}>
        <div className={listPreview('ImageWrapper')}>
          <Link
            to={articleUrl}
            aria-label={`Read more about: ${alt}`}
            trackGtmClick={trackGtmClick}
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          >
            <div title={alt}>
              <Image
                className={listPreview('Image', {
                  publication: isPublicationType,
                })}
                src={src}
                alt={alt}
                fallback="brand"
                width={300}
              />
            </div>
          </Link>
          {icon && (
            <Link
              className={listPreview('IconWrapper')}
              to={listPageUrl}
              trackGtmClick={trackGtmClick}
              title={`Link to all ${contentType.toLowerCase()}`}
              aria-label="Redirect to All page"
              {...linkAttrs}
              data-testid="content-preview-icon-link"
            >
              <Icon icon={icon} className={listPreview('Icon')} />
            </Link>
          )}
        </div>
        <div className={listPreview('ContentWrapper')}>
          <div className={listPreview('KeywordWrapper')}>
            <Keyword
              variant="alternate"
              url={categoryUrl}
              trackGtmClick={trackGtmClick}
              title={categoryName}
              disabled={
                categoryName === SPONSORED_CONTENT ||
                publicationType === 'publicationTitle'
              }
              className={listPreview('Keyword')}
            />
          </div>
          <div className={listPreview('TitleWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              className={listPreview('Title')}
              data-testid="preview-default-title"
              {...linkAttrs}
              dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            />
            {isPublicationType && (
              <div
                className={listPreview('Summary')}
                dangerouslySetInnerHTML={{
                  __html: renderMarkdown(articleSummary || ''),
                }}
              />
            )}
          </div>
          <div className={listPreview('MobileTitleWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              className={listPreview('Title')}
              data-testid="preview-mobile-title"
              {...linkAttrs}
              dangerouslySetInnerHTML={{
                __html: renderMarkdown(mobileHeadline),
              }}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            />
            {isPublicationType && (
              <div className={listPreview('Summary')}>{articleSummary}</div>
            )}
          </div>
          {!isPublicationType && (
            <div className={listPreview('Footer')}>
              <span
                className={listPreview('Date')}
                data-testid="list-preview-date"
              >
                {formatDate(date)}
              </span>
              <CardTime
                timeRead={timeRead}
                date={date}
                webinarStartTime={webinarStartTime}
                contentType={contentType}
              />
            </div>
          )}
        </div>
      </div>
      {contributors.length > 0 && !isPublicationType && (
        <div className={listPreview('ContributorsWrapper')}>
          <Contributors
            variant="slimline"
            cardVariant={variant}
            contributors={contributors}
            showContributorTitle={showContributorTitle}
            trackGtmClick={trackGtmClick}
          />
        </div>
      )}
    </div>
  )
}
