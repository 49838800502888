/* eslint-disable jsx-a11y/anchor-is-valid */
import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { ContributorFocusedProps } from '../type'
import { formatDate } from '../utils'

const contributorFocusedCard = Bem('ContributorFocusedCard')

export const ContributorFocusedCard = (props: ContributorFocusedProps) => {
  const {
    articleUrl,
    categoryName,
    categoryUrl,
    articleName,
    date,
    webinarStartTime,
    timeRead,
    contributors = [],
    contentType,
    linkAttrs = {},
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  // this module set only show the first contributor
  const contributor = contributors[0]

  return (
    <div className={contributorFocusedCard()}>
      {contributor && (
        <div className={contributorFocusedCard('Wrapper')}>
          <div className={contributorFocusedCard('Body')}>
            <Link
              to={contributor.link}
              trackGtmClick={trackGtmClick}
              aria-label={contributor.name}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            >
              <span
                className={contributorFocusedCard('ScreenReaderText')}
              >{`Picture of ${contributor.name}`}</span>
              <Image
                src={contributor.avatar}
                className={contributorFocusedCard('Avatar')}
                alt=""
                width={150}
                aria-hidden
                fallback="contributor"
              />
            </Link>
          </div>
          <div className={contributorFocusedCard('InfoWrapper')}>
            <Link
              to={contributor.link}
              trackGtmClick={trackGtmClick}
              className={contributorFocusedCard('ContributorName')}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            >
              {contributor.name}
            </Link>
            {contributor?.title && (
              <p
                className={contributorFocusedCard('ContributorTitle')}
                data-testid="preview-contributor-title"
              >
                {contributor.title}
              </p>
            )}
          </div>
        </div>
      )}

      <div className={contributorFocusedCard('ContentWrapper')}>
        <Keyword
          variant="alternate"
          url={categoryUrl}
          trackGtmClick={trackGtmClick}
          title={categoryName}
        />
        <div className={contributorFocusedCard('TitleWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={contributorFocusedCard('Title')}
            data-testid="contributor-focused-card-title"
            {...linkAttrs}
            dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          />
        </div>
        <div className={contributorFocusedCard('Footer')}>
          <span className={contributorFocusedCard('Date')}>
            {formatDate(date)}
          </span>
          <CardTime
            timeRead={timeRead}
            date={date}
            webinarStartTime={webinarStartTime}
            contentType={contentType}
          />
        </div>
      </div>
    </div>
  )
}
