import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Contributors } from '@scm/ui-component-contributors'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown, SPONSORED_CONTENT } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { VerticalPreviewContentCardProps } from '../type'
import { formatDate } from '../utils'

const verticalCard = Bem('VerticalCard')

export const VerticalCard = (props: VerticalPreviewContentCardProps) => {
  const {
    articleName,
    mobileHeadline,
    articleUrl = '',
    date,
    webinarStartTime,
    timeRead,
    contributors = [],
    contentType,
    linkAttrs = {},
    trackGtmClick = () => {},
    isShowDate = true,
    isShowContributor = true,
    isShowSponsoredContent = false,
    categoryName,
    seriesType,
    seriesUrl,
    trackGtmUid,
    trackGtmTitle,
  } = props
  const isSponsoredContent =
    isShowSponsoredContent && categoryName === SPONSORED_CONTENT

  return (
    <div className={verticalCard()}>
      <div className={verticalCard('Body')}>
        {isSponsoredContent && (
          <div className={verticalCard('SponsoredContent')}>
            {SPONSORED_CONTENT}
          </div>
        )}
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={verticalCard('Title', { displayOption: 'default' })}
          data-testid="preview-default-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
          style={contributors.length === 0 ? { marginBottom: 0 } : {}}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={verticalCard('Title', { displayOption: 'mobile' })}
          data-testid="preview-mobile-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{
            __html:
              renderMarkdown(mobileHeadline) || renderMarkdown(articleName),
          }}
          style={contributors.length === 0 ? { marginBottom: 0 } : {}}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />

        {isShowContributor && contributors.length > 0 && (
          <Contributors
            variant="slimline"
            hasAvatar={false}
            contributors={contributors}
            classNames={verticalCard('ContributorsWrapper')}
            showContributorTitle={false}
            trackGtmClick={trackGtmClick}
          />
        )}
        {seriesType && (
          <Keyword
            variant="alternate"
            url={seriesUrl || ''}
            trackGtmClick={trackGtmClick}
            title={seriesType}
            disabled={!seriesUrl}
            className={verticalCard('Keyword')}
          />
        )}
      </div>
      {isShowDate && (
        <div className={verticalCard('Footer')}>
          <span
            className={verticalCard('Date')}
            data-testid="vertical-card-date"
          >
            {formatDate(date)}
          </span>
          <CardTime
            timeRead={timeRead}
            date={date}
            webinarStartTime={webinarStartTime}
            contentType={contentType}
            isSeparatorNeeded={false}
          />
        </div>
      )}
    </div>
  )
}
