import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { TopFeaturedProps } from '../type'
import { formatDate } from '../utils'

const topFeaturedPreview = Bem('TopFeaturedPreview')

export const TopFeaturedPreview = (props: TopFeaturedProps) => {
  const {
    articleName,
    mobileHeadline,
    articleUrl = '',
    date,
    webinarStartTime,
    articleSummary = '',
    timeRead,
    contentType,
    linkAttrs = {},
    trackGtmClick = () => {},
    isShowDate = true,
    trackGtmUid,
    trackGtmTitle,
  } = props
  return (
    <>
      <div className={topFeaturedPreview()}>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={topFeaturedPreview('TitleWrapper')}
          {...linkAttrs}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        >
          <h2
            className={topFeaturedPreview('Title')}
            data-testid="preview-default-title"
            dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
          />
        </Link>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={topFeaturedPreview('MobileTitleWrapper')}
          {...linkAttrs}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        >
          <h2
            className={topFeaturedPreview('Title')}
            data-testid="preview-mobile-title"
            dangerouslySetInnerHTML={{
              __html:
                renderMarkdown(mobileHeadline) || renderMarkdown(articleName),
            }}
          />
        </Link>
        {articleSummary && (
          <p
            className={topFeaturedPreview('Summary')}
            dangerouslySetInnerHTML={{
              __html: renderMarkdown(articleSummary),
            }}
          />
        )}
      </div>
      {isShowDate && (
        <div className={topFeaturedPreview('Footer')}>
          <span className={topFeaturedPreview('Date')}>{formatDate(date)}</span>
          <CardTime
            timeRead={timeRead}
            date={date}
            webinarStartTime={webinarStartTime}
            contentType={contentType}
          />
        </div>
      )}
    </>
  )
}
