import { handlePaidContentClickEvent } from '@scm/tag-manager'
import {
  ArticleReadTime,
  ContentType,
} from '@scm/ui-component-article-read-time'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { ContentCardSpotlightProps, ContentCardSpotlightPropsV2 } from '../type'
import { formatDate, getContentIconUrl, getSummary } from '../utils'

const contentCardSpotlight = Bem('ContentCardSpotlight')

export const ContentCardSpotlight = (
  props: ContentCardSpotlightProps | ContentCardSpotlightPropsV2,
) => {
  const {
    articleUrl,
    listPageUrl,
    thumbnail,
    categoryName,
    categoryUrl,
    articleName,
    mobileHeadline,
    date,
    timeRead,
    contentType,
    linkAttrs = {},
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src, alt } = thumbnail
  const icon = getContentIconUrl(contentType as ContentType)
  const isSponsored = categoryName === 'Sponsored Content'
  const summary = getSummary({ ...props })

  return (
    <div className={contentCardSpotlight()}>
      <div className={contentCardSpotlight('ImageSection')}>
        <div className={contentCardSpotlight('ImageContainer')}>
          <div className={contentCardSpotlight('ImageAspectWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            >
              <Image
                className={contentCardSpotlight('Image')}
                src={src}
                alt={alt}
                fallback="brand"
                width={700}
              />
            </Link>
          </div>
        </div>
        {icon && (
          <Link
            className={contentCardSpotlight('IconWrapper')}
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            title={`Link to all ${contentType.toLowerCase()}`}
            aria-label="Redirect to All page"
            {...linkAttrs}
            data-testid="content-preview-icon-link"
          >
            <Icon icon={icon} className={contentCardSpotlight('Icon')} />
          </Link>
        )}
      </div>
      <div className={contentCardSpotlight('TitleSection')}>
        {isSponsored && (
          <div className={contentCardSpotlight('KeywordWrapper')}>
            <Keyword
              variant="alternate"
              url={categoryUrl}
              trackGtmClick={trackGtmClick}
              title="Sponsored Content"
              disabled={isSponsored}
              className={contentCardSpotlight('Keyword')}
            />
          </div>
        )}
        <div className={contentCardSpotlight('TitleWrapper')}>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={contentCardSpotlight('Title', {
              displayOption: 'default',
            })}
            data-testid="preview-default-title"
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          >
            {articleName}
          </Link>
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={contentCardSpotlight('Title', {
              displayOption: 'mobile',
            })}
            data-testid="preview-mobile-title"
            {...linkAttrs}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          >
            {mobileHeadline || articleName}
          </Link>
        </div>
        <div
          data-testid="preview-card-summary"
          className={contentCardSpotlight('Summary')}
        >
          {summary}
        </div>
        <div className={contentCardSpotlight('Footer')}>
          <span className={contentCardSpotlight('Date')}>
            {formatDate(date)}
          </span>
          {timeRead ? (
            <div className={contentCardSpotlight('ReadTimeWrapper')}>
              <span className={contentCardSpotlight('FooterSeparator')}>|</span>
              <ArticleReadTime
                minutes={timeRead}
                classNames={contentCardSpotlight('ReadTime')}
                size="small"
                contentType={contentType as ContentType}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
