import { Link } from '@remix-run/react'
import camelCase from 'lodash/camelCase'

import { Bem, SPONSORED_CONTENT } from '@scm/ui-core'

import { KeywordProps } from './type'

const keyword = Bem('Keyword')

export const normaliseKeywordTitle = (name: string) => {
  return name
    .replace(/-and-/g, ' & ')
    .replace(/-or-/g, '/')
    .replace(/_/g, ' ')
    .trim()
}

export const Keyword = ({
  url,
  title,
  className,
  style,
  variant,
  disabled,
  trackGtmClick = () => {},
}: KeywordProps) => {
  const commonClassName = keyword(
    { variant, title: camelCase(title), disabled },
    [className],
  )

  if (title === SPONSORED_CONTENT) {
    return (
      <span data-component="keyword" className={commonClassName}>
        {title}
      </span>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      data-component="keyword"
      to={url}
      onClick={e => {
        trackGtmClick(url, e)
      }}
      className={commonClassName}
      style={style}
    >
      {normaliseKeywordTitle(title)}
    </Link>
  )
}
