import { handlePaidContentClickEvent } from '@scm/tag-manager'
import {
  ArticleReadTime,
  ContentType,
} from '@scm/ui-component-article-read-time'
import { Contributors } from '@scm/ui-component-contributors'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { ListCardWithSummaryProps, ListCardWithSummaryPropsV2 } from '../type'
import { formatDate, getContentIconUrl, getSummary } from '../utils'

const cardWithSummary = Bem('ListCardWithSummary')

export const ListCardWithSummary = (
  props: ListCardWithSummaryProps | ListCardWithSummaryPropsV2,
) => {
  const {
    articleUrl,
    listPageUrl,
    thumbnail,
    categoryName,
    categoryUrl,
    articleName,
    mobileHeadline,
    date,
    timeRead,
    contributors = [],
    contentType,
    showContributorTitle,
    linkAttrs = {},
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src, alt } = thumbnail
  const icon = getContentIconUrl(contentType as ContentType)
  const summary = getSummary({ ...props })

  return (
    <div className={cardWithSummary()}>
      <div className={cardWithSummary('ImageAndTitleWrapper')}>
        <div className={cardWithSummary('ImageSection')}>
          <div className={cardWithSummary('ImageContainer')}>
            <div className={cardWithSummary('ImageAspectWrapper')}>
              <Link
                to={articleUrl}
                trackGtmClick={trackGtmClick}
                {...linkAttrs}
                trackPaidContentClick={() =>
                  handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
                }
              >
                <Image
                  className={cardWithSummary('Image')}
                  src={src}
                  alt={alt}
                  fallback="brand"
                  width={300}
                />
              </Link>
            </div>
          </div>
          {icon && (
            <Link
              className={cardWithSummary('IconWrapper')}
              to={listPageUrl}
              trackGtmClick={trackGtmClick}
              title={`Link to all ${contentType.toLowerCase()}`}
              aria-label="Redirect to All page"
              {...linkAttrs}
              data-testid="content-preview-icon-link"
            >
              <Icon icon={icon} className={cardWithSummary('Icon')} />
            </Link>
          )}
        </div>
        <div className={cardWithSummary('TitleSection')}>
          <div className={cardWithSummary('KeywordWrapper')}>
            <Keyword
              variant="alternate"
              url={categoryUrl}
              trackGtmClick={trackGtmClick}
              title={categoryName}
              disabled={categoryName === 'Sponsored Content'}
              className={cardWithSummary('Keyword')}
            />
          </div>
          <div className={cardWithSummary('TitleWrapper')}>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              className={cardWithSummary('Title', {
                displayOption: 'default',
              })}
              data-testid="preview-default-title"
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            >
              {articleName}
            </Link>
            <Link
              to={articleUrl}
              trackGtmClick={trackGtmClick}
              className={cardWithSummary('Title', {
                displayOption: 'mobile',
              })}
              data-testid="preview-mobile-title"
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
              }
            >
              {mobileHeadline || articleName}
            </Link>
          </div>
        </div>
      </div>
      <div
        data-testid="preview-card-summary"
        className={cardWithSummary('Summary')}
      >
        {summary}
      </div>
      <div className={cardWithSummary('Footer')}>
        <span className={cardWithSummary('Date')}>{formatDate(date)}</span>
        {timeRead ? (
          <div className={cardWithSummary('ReadTimeWrapper')}>
            <span className={cardWithSummary('FooterSeparator')}>|</span>
            <ArticleReadTime
              minutes={timeRead}
              classNames={cardWithSummary('ReadTime')}
              size="small"
              contentType={contentType as ContentType}
            />
          </div>
        ) : null}
      </div>
      {contributors.length > 0 && (
        <div className={cardWithSummary('ContributorsWrapper')}>
          <Contributors
            variant="slimline"
            cardVariant="small"
            contributors={contributors}
            showContributorTitle={showContributorTitle}
            trackGtmClick={trackGtmClick}
          />
        </div>
      )}
    </div>
  )
}
