import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Contributors } from '@scm/ui-component-contributors'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'
import { ClassName } from '@scm/ui-tailwind'

import { ContentCardProps } from '../type'
import { formatDate } from '../utils'

const contentCard = Bem('ContentCard')

export const ContentCard = (props: ContentCardProps) => {
  const {
    articleName,
    mobileHeadline,
    articleUrl = '',
    date,
    webinarStartTime,
    sponsoredType = '',
    timeRead,
    variant,
    contributors = [],
    contentType,
    linkAttrs = {},
    isShowDate = true,
    isShowContributor = true,
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  return (
    <div className={contentCard()}>
      <div className={contentCard('Body')}>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={contentCard('Title', { displayOption: 'default' })}
          data-testid="preview-default-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={contentCard('Title', { displayOption: 'mobile' })}
          data-testid="preview-mobile-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{
            __html:
              renderMarkdown(mobileHeadline) || renderMarkdown(articleName),
          }}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />
        <Sponsored
          sponsoredType={sponsoredType}
          className={contentCard('Date')}
        />
        {isShowContributor && contributors.length > 0 && (
          <Contributors
            variant="slimline"
            hasAvatar={false}
            contributors={contributors}
            cardVariant={variant}
            trackGtmClick={trackGtmClick}
            showContributorTitle={false}
          />
        )}
      </div>
      {isShowDate && (
        <div className={contentCard('Footer')}>
          <span className={contentCard('Date')} data-testid="content-card-date">
            {formatDate(date)}
          </span>
          {variant === 'normal' && (
            <CardTime
              timeRead={timeRead}
              date={date}
              webinarStartTime={webinarStartTime}
              contentType={contentType}
              isSeparatorNeeded={false}
            />
          )}
        </div>
      )}
    </div>
  )
}

interface SponsoredProps {
  sponsoredType: string
  className?: ClassName
  separator?: boolean
}

const Sponsored = ({ sponsoredType, className, separator }: SponsoredProps) => {
  return sponsoredType === 'Sponsored' ? (
    <>
      {separator && (
        <span className={contentCard('Separator', [className])}>|</span>
      )}
      <span className={className} data-testid="content-card-sponsored">
        Sponsored
      </span>
    </>
  ) : null
}
