import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Contributors } from '@scm/ui-component-contributors'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { ArticlePreviewProps } from '../type'
import { formatDate } from '../utils'
import { useSelector } from 'react-redux'

const articlePreview = Bem('ArticlePreview')

export const ArticlePreview = (props: ArticlePreviewProps) => {
  const {
    articleName,
    mobileHeadline,
    articleUrl = '',
    date,
    webinarStartTime,
    articleSummary = '',
    contributors = [],
    timeRead,
    contentType,
    variant,
    linkAttrs = {},
    isShowDate = true,
    isShowContributor = true,
    trackGtmClick = () => {},
    trackGtmUid,
    trackGtmTitle,
  } = props
  const config = useSelector((state: any) => state.config)
  return (
    <div className={articlePreview()}>
      <div className={articlePreview('Body')}>
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={articlePreview('Title', { displayOption: 'default' })}
          data-testid="preview-default-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />
        <Link
          to={articleUrl}
          trackGtmClick={trackGtmClick}
          className={articlePreview('Title', { displayOption: 'mobile' })}
          data-testid="preview-mobile-title"
          {...linkAttrs}
          dangerouslySetInnerHTML={{
            __html:
              renderMarkdown(mobileHeadline) || renderMarkdown(articleName),
          }}
          trackPaidContentClick={() =>
            handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
          }
        />
        <p
          data-testid="preview-card-summary"
          className={articlePreview('Summary')}
          dangerouslySetInnerHTML={{
            __html: renderMarkdown(articleSummary),
          }}
        />
        {isShowContributor && contributors.length > 0 && (
          <Contributors
            variant="slimline"
            cardVariant={variant}
            contributors={contributors}
            trackGtmClick={trackGtmClick}
          />
        )}
      </div>
      {isShowDate && (
        <div className={articlePreview('Footer')}>
          <span className={articlePreview('Date')}>{formatDate(date)}</span>
          <CardTime
            timeRead={timeRead}
            date={date}
            webinarStartTime={webinarStartTime}
            contentType={contentType}
            isSeparatorNeeded={config.theme !== 'theme2'}
          />
        </div>
      )}
    </div>
  )
}
