import { Icon, IconType } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'

import { ArticleReadTimeProps, ContentType } from './type'

const articleReadTime = Bem('ArticleReadTime')

export const ArticleReadTime = (props: ArticleReadTimeProps) => {
  const { minutes, classNames, contentType, size } = props
  const durationUnit = getDurationUnit(minutes, contentType)

  if (!minutes) return null

  return (
    <div
      data-component="article-read-time"
      className={articleReadTime({ size }, [classNames])}
    >
      {'hasIcon' in props && props.hasIcon ? (
        <Icon icon={getIcon(contentType)} className={articleReadTime('Icon')} />
      ) : null}
      <span>
        {convertCounter(minutes, contentType)} {durationUnit}
      </span>
    </div>
  )
}

export const convertCounter = (
  counter: number,
  contentType: ContentType,
): string => {
  if (contentType === 'Slideshow') return counter.toString()

  const hours = Math.floor(counter / 60)
  const minutes = counter % 60

  if (!hours && minutes) return `${minutes} Min`

  if (hours && !minutes) return `${hours} Hr`

  return `${hours}h ${minutes}m`
}

export const getDurationUnit = (
  duration: ArticleReadTimeProps['minutes'],
  contentType: ContentType,
): string => {
  switch (contentType) {
    case 'Article':
    case 'Document':
    case 'Magazine':
      return 'Read'
    case 'Video':
    case 'Webinar':
      return 'View'
    case 'Podcast':
      return 'Listen'
    case 'Slideshow':
      return `Slide${duration > 1 ? 's' : ''}`
    default:
      return 'Read'
  }
}

const getIcon = (contentType: ContentType): IconType => {
  switch (contentType) {
    case 'Article':
    case 'Document':
    case 'Magazine':
    case 'Video':
    case 'Webinar':
    case 'Podcast':
      return 'Clock'
    case 'Slideshow':
      return 'Images'
    default:
      return 'Clock'
  }
}
