import { useEffect } from 'react'
import {
  EcommerceItem,
  ListPaidGating,
  PaidContentEventProps,
  PaidContentListItem,
  PaidGating,
  PaidProductTaxonomy,
} from './ecomTypes'
import { pushToDataLayer } from './gtm'
import { EventType } from './types'

export const usePaidContentListLoaded = (
  title: string,
  pathname: string,
  items: PaidContentListItem[],
) => {
  useEffect(() => {
    pushPaidContentListEvent(title, pathname, getPaidItemsList(items))
  }, [])
}

const createDataLayerEcommerceItem = ({
  pathname,
  title,
  paidProductTaxonomies,
}: PaidContentEventProps & {
  paidProductTaxonomies: PaidProductTaxonomy[]
}) => {
  const joinedTaxonomies = paidProductTaxonomies
    .map(taxonomy => taxonomy.paidProductId)
    .join('|')

  return {
    item_id: pathname,
    item_name: title,
    item_category: joinedTaxonomies,
  }
}

const isPaidProductWithTaxonomies = (
  paidGating?: PaidGating,
): paidGating is NonNullable<PaidGating> => {
  const activePaidProductTaxonomies = paidGating?.paidProductTaxonomies
    ? paidGating?.paidProductTaxonomies.filter(
        taxonomy => taxonomy.activeProduct === true,
      )
    : []
  return Boolean(
    paidGating?.isPaidContent && activePaidProductTaxonomies.length > 0,
  )
}

const transformPaidGating = (
  paidGating?: ListPaidGating,
): PaidGating | undefined => {
  if (!paidGating) return undefined
  const { paidProductTaxonomy, paidContent } = paidGating
  return {
    isPaidContent: paidContent,
    paidProductTaxonomies: paidProductTaxonomy,
  }
}

const transformPaidGatingList = (contents: PaidContentListItem[]) => {
  return contents.map(item => ({
    ...item,
    paidGating: transformPaidGating(item.paidGating),
  }))
}

export const getPaidItemsList = (contents: PaidContentListItem[]) => {
  const mappedPaidItems = transformPaidGatingList(contents)

  return mappedPaidItems
    .filter(item => isPaidProductWithTaxonomies(item.paidGating))
    .map((item, index) => ({
      ...createDataLayerEcommerceItem({
        pathname: item?.articleUrl || item?.url || item?.videoUrl || '',
        title: item?.articleName || item?.title || item?.videoTitle || '',
        paidProductTaxonomies: item.paidGating?.paidProductTaxonomies || [],
      }),
      index,
    }))
}

export const pushAddToCartEvent = (props: PaidContentEventProps) => {
  const { pathname, title, paidGating } = props

  if (!isPaidProductWithTaxonomies(paidGating)) {
    return
  }

  pushToDataLayer({ ecommerce: null })
  pushToDataLayer({
    event: EventType.ADD_TO_CART,
    ecommerce: {
      items: [
        {
          ...createDataLayerEcommerceItem({
            pathname,
            title,
            paidProductTaxonomies: paidGating.paidProductTaxonomies,
          }),
          index: 0,
        },
      ],
    },
  })
}

export const pushPaidContentLoadedEvent = (props: PaidContentEventProps) => {
  const { paidGating, isUserEntitled, pathname, title } = props

  if (isUserEntitled || !isPaidProductWithTaxonomies(paidGating)) {
    return
  }
  pushToDataLayer({ ecommerce: null })
  pushToDataLayer({
    event: EventType.VIEW_ITEM,
    ecommerce: {
      items: [
        {
          ...createDataLayerEcommerceItem({
            pathname,
            title,
            paidProductTaxonomies: paidGating.paidProductTaxonomies,
          }),
          index: 0,
        },
      ],
    },
  })
}

export const pushPaidContentListEvent = (
  title: string,
  pathname: string,
  items: EcommerceItem[],
) => {
  if (items.length > 0) {
    pushToDataLayer({ ecommerce: null })
    pushToDataLayer({
      event: EventType.VIEW_ITEM_LIST,
      ecommerce: {
        item_list_name: title,
        item_list_id: pathname,
        items,
      },
    })
  }
}

const pushPaidContentClickEvent = (
  title: string,
  pathname: string,
  item: EcommerceItem,
) => {
  pushToDataLayer({ ecommerce: null })
  pushToDataLayer({
    event: EventType.SELECT_ITEM,
    ecommerce: {
      item_list_name: title,
      item_list_id: pathname,
      items: [item],
    },
  })
}

export const handlePaidContentClickEvent = (
  item: PaidContentListItem,
  listName?: string,
  listId?: string,
) => {
  const {
    paidGating,
    articleUrl,
    articleName,
    title,
    url,
    videoTitle,
    videoUrl,
  } = item
  const mappedPaidGating = transformPaidGating(paidGating)

  if (isPaidProductWithTaxonomies(mappedPaidGating)) {
    const mappedItem = {
      ...createDataLayerEcommerceItem({
        pathname: articleUrl || url || videoUrl || '',
        title: articleName || title || videoTitle || '',
        paidProductTaxonomies: mappedPaidGating?.paidProductTaxonomies || [],
      }),
      index: 0,
    }
    pushPaidContentClickEvent(listName || '', listId || '', mappedItem)
  }
}
