/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from '@remix-run/react'
import camelCase from 'lodash/camelCase'

import { Image } from '@scm/ui-component-image'
import { Bem, convertDateToLongDateString } from '@scm/ui-core'

import { ContributorsProps } from './type'

const contributorsComponent = Bem('Contributors')

// eslint-disable-next-line complexity
export const Contributors = ({
  classNames,
  date,
  contributors,
  variant,
  hasAvatar = true,
  cardVariant,
  isWhiteMode = false,
  showContributorTitle = true,
  showAllContributors = false,
  trackGtmClick = () => {},
}: ContributorsProps) => {
  const truncatedContributors = contributors.slice(0, 2)
  const contributorsToDisplay = showAllContributors
    ? contributors
    : truncatedContributors

  let extraContributorsCount = 0
  if (contributors.length > 0) {
    extraContributorsCount =
      contributors.length > truncatedContributors.length
        ? contributors.length - truncatedContributors.length
        : 0
  }

  const checkShowTitle =
    showContributorTitle && truncatedContributors.length === 1

  return (
    <div
      className={contributorsComponent(
        {
          variant,
          cardVariant: camelCase(cardVariant),
          isWhiteMode,
          hasAvatar,
        },
        [classNames],
      )}
      data-component="contributors"
    >
      {hasAvatar && (
        <div
          className={contributorsComponent('AvatarWrapper', {
            isHidden: !truncatedContributors.length,
          })}
        >
          {truncatedContributors.map(({ avatar, name, link }, index) => (
            <Link
              to={link}
              onClick={e => {
                trackGtmClick(link, e)
              }}
              key={`contributor-img-${name}-${index}`}
              className={contributorsComponent('AvatarLink', {
                isMultiple: contributors.length > 1,
              })}
              aria-label={name}
            >
              <Image
                src={avatar}
                className={contributorsComponent('Avatar')}
                alt={`Picture of ${name}`}
                aria-hidden
                fallback="contributor"
                width={100}
                data-testid="contributor-avatar"
              />
            </Link>
          ))}
          {extraContributorsCount > 0 && (
            <span
              className={contributorsComponent('ContributorsCount')}
              data-testid="contributors-count"
            >
              <span> +{extraContributorsCount}</span>
            </span>
          )}
        </div>
      )}
      <div className={contributorsComponent('InfoWrapper')}>
        {variant === 'slimline' && (
          <span
            className={contributorsComponent('ByText')}
            data-testid="by-text"
          >
            by
          </span>
        )}
        {contributorsToDisplay.map((contributor, index) => {
          const isLast = index === contributorsToDisplay.length - 1
          const showComma = contributorsToDisplay.length > 1 && !isLast
          return (
            <Link
              key={`contributor-name-${contributor.name}-${index}`}
              to={contributor.link}
              onClick={e => {
                trackGtmClick(contributor.link, e)
              }}
              className={contributorsComponent('ContributorName', {
                showComma,
              })}
              data-testid="contributor-name"
            >
              {contributor.name}
              {showComma ? ', ' : ''}
            </Link>
          )
        })}
        {extraContributorsCount > 0 && !showAllContributors && (
          <span
            className={contributorsComponent('MoreText')}
            data-testid="more-text"
          >
            and {extraContributorsCount} more
          </span>
        )}
        {checkShowTitle && truncatedContributors[0]?.role && (
          <span
            className={contributorsComponent('ContributorTitle')}
            data-testid="contributor-title"
          >
            {', '}
            {truncatedContributors[0].role}
          </span>
        )}
        {date && variant === 'normal' && (
          <p
            className={contributorsComponent('Date')}
            data-testid="contributors-date"
          >
            {convertDateToLongDateString(new Date(date))}
          </p>
        )}
      </div>
    </div>
  )
}
