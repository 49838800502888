import camelCase from 'lodash/camelCase'
import { FC } from 'react'

import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, SPONSORED_CONTENT } from '@scm/ui-core'

import {
  ArticlePreview,
  ContentCard,
  ContentCardSpotlight,
  ContributorFocusedCard,
  HorizontalCard,
  ListCardWithSummary,
  ListPreview,
  ListPreviewResource,
  TopFeaturedPreview,
  VerticalCard,
} from './subComponents'
import { ContentPreviewProps } from './type'
import { getContentIconUrl } from './utils'
import { capitalize } from 'lodash'

const contentPreview = Bem('ContentPreview')

// eslint-disable-next-line complexity
export const ContentPreview = (props: ContentPreviewProps) => {
  const { className, variant } = props

  return (
    <div
      className={contentPreview({}, [className])}
      data-module="content-preview"
      data-variant={variant}
    >
      {variant === 'small' && <ListPreview {...props} />}

      {variant === 'card-horizontal' && <HorizontalCard {...props} />}

      {variant === 'card-vertical' && (
        <ContentWrapper {...props}>
          <VerticalCard {...props} />
        </ContentWrapper>
      )}

      {variant === 'card-recipe' && (
        <ContentWrapper
          {...props}
          categoryName={capitalize(props.categoryName)}
        >
          <VerticalCard {...props} />
        </ContentWrapper>
      )}

      {(variant === 'normal' || variant === 'normal-has-tag') && (
        <ContentWrapper {...props}>
          <ContentCard {...props} />
        </ContentWrapper>
      )}

      {variant === 'summary' && (
        <ContentWrapper {...props}>
          <ArticlePreview {...props} />
        </ContentWrapper>
      )}

      {variant === 'contributor-focused' && (
        <ContributorFocusedCard {...props} />
      )}

      {variant === 'top-featured' && (
        <ContentWrapper {...props}>
          <TopFeaturedPreview {...props} />
        </ContentWrapper>
      )}
      {variant === 'resources' && <ListPreviewResource {...props} />}

      {variant === 'small-has-summary' && <ListCardWithSummary {...props} />}

      {variant === 'content-spotlight' && <ContentCardSpotlight {...props} />}
    </div>
  )
}

const ContentWrapper: FC<ContentPreviewProps> = props => {
  const {
    thumbnail,
    articleUrl = '',
    listPageUrl,
    categoryName,
    categoryUrl,
    children,
    variant,
    contentType,
    linkAttrs,
    trackGtmClick = () => {},
    isShowCategory = true,
    trackGtmUid,
    trackGtmTitle,
  } = props
  const { src = '', alt } = thumbnail
  const icon = getContentIconUrl(contentType)

  return (
    <div
      className={contentPreview('Wrapper', {
        variant: camelCase(variant),
        ...((variant === 'normal' || variant === 'normal-has-tag') && {
          articleSize: props.articleSize,
        }),
      })}
    >
      <Link
        data-testid="thumbnail"
        to={articleUrl}
        trackGtmClick={trackGtmClick}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
        }
        {...linkAttrs}
      >
        <div className={contentPreview('ImageContainer')}>
          <span
            data-testid="content-preview-screen-reader-text"
            className={contentPreview('ImageScreenReaderText')}
          >
            {alt || 'thumbnail'}
          </span>
          <Image
            className={contentPreview('Image')}
            src={src}
            alt=""
            fallback="brand"
            data-testid="content-preview-image"
          />
        </div>
      </Link>
      {isShowCategory && (
        <Keyword
          className={contentPreview('Category', {
            isSponsoredContent: categoryName === SPONSORED_CONTENT,
          })}
          title={
            contentType !== 'Recipe' ? categoryName : capitalize(categoryName)
          }
          url={categoryUrl}
          trackGtmClick={trackGtmClick}
          disabled={
            categoryName === SPONSORED_CONTENT || contentType === 'Recipe'
          }
          variant="standard"
        />
      )}
      {variant !== 'top-featured' && icon && (
        <Link
          className={contentPreview('IconWrapper')}
          title={`Link to all ${contentType.toLowerCase()}`}
          {...linkAttrs}
          to={listPageUrl}
          trackGtmClick={trackGtmClick}
          data-testid="content-preview-icon-link"
          {...linkAttrs}
        >
          <Icon icon={icon} className={contentPreview('Icon')} />
        </Link>
      )}
      {children}
    </div>
  )
}
