import { compareAsc } from 'date-fns'

import { ArticleReadTime } from '@scm/ui-component-article-read-time'
import { Bem, replaceTimezone } from '@scm/ui-core'

import { CardTimeProps } from './type'

const cardTime = Bem('CardTime')

export const CardTime = ({
  classNames,
  timeRead,
  date,
  webinarStartTime,
  contentType,
  isSeparatorNeeded = true,
}: CardTimeProps) => {
  const currentDate = new Date()
  const webinarStartTimeMapped = replaceTimezone(webinarStartTime)
  const webinarDate = new Date(`${date} ${webinarStartTimeMapped}`)
  const isUpcomingDate = compareAsc(webinarDate, currentDate) > 0

  if (contentType === 'Webinar' && isUpcomingDate) {
    return (
      <div data-module="card-time" className={cardTime({}, [classNames])}>
        {isSeparatorNeeded && (
          <span className={cardTime('SeparatorLine')}>|</span>
        )}
        <div className={cardTime('StartTime')}>
          <span>{webinarStartTime}</span>
        </div>
      </div>
    )
  }

  return timeRead ? (
    <div data-module="card-time" className={cardTime({}, [classNames])}>
      {isSeparatorNeeded && (
        <span className={cardTime('SeparatorLine')}>|</span>
      )}
      <ArticleReadTime
        minutes={timeRead}
        classNames={cardTime('ReadTime')}
        size="small"
        contentType={contentType}
        data-testid="content-card-article-readtime"
      />
    </div>
  ) : null
}
